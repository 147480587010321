import React from "react";
import lol from '../../static/lol.jpg';
import pubgm from '../../static/pubgm.jpg';
import Layout from "../components/Layout";

export default function Home() {
  return (
    <Layout>
    <div>
        <div id="menu">
          <h1 className="mt-10 md:mt-60 font-bold cursor-pointer">#vkesports</h1>
            <div id="list" className="grid grid-cols-2">
                <div className="mt-5">
                  <a href="/lol">
                    <img src={lol} width={250} height={150} alt="lol" />
                  </a>
                </div>
                <div className="mt-5">
                  <a href="pubgmc.vkesports.com">
                    <img src={pubgm} width={250} height={150} alt="pubgm" />
                  </a>
                </div>
            </div>
          <h3 className="mt-10 font-black text-3xl alert-success">“Simplicity is the ultimate sophistication.” </h3>
          <h3 className="font-black alert-success"> – Leonardo da Vinci</h3>
        </div>
        <div className="mb-10">
            <a className="font-bold" href="/news">News</a>
            <a className="font-bold" href="/members">Members</a>
            <a className="font-bold" href="forum.vkesports.com">Forum</a>
            <a className="font-bold" href="ts3server://ts.vkesports.com/">TeamSpeak</a>
          </div>
        <h6 className="font-semibold">&copy; 2021 6thbyte</h6>
    </div>
    </Layout>
  )
}