import React from "react";

export default function Layout({ children }) {
    return (
        <div>
            <section class="intro">
                <div class="inner">
                    <div class="content">

                        { children }

                    </div>
                </div>
            </section>
        </div>
    )
}